import classNames from "classnames";
import { FC } from "react";

const SPLoader: FC<{
  type?: "dots" | "circle-notch";
  notCentered?: boolean;
}> = ({ type, notCentered }) => {
  if (type === "circle-notch") {
    return <i className="fas fa-spin fa-circle-notch" />;
  }

  return (
    <div
      className={classNames({
        "ball-pulse": true,
        "text-center": !notCentered,
      })}
    >
      <div />
      <div />
      <div />
    </div>
  );
};

export default SPLoader;
