//import Components
import React, { createContext, useContext, useState } from 'react'

//----------------[This is code for global variables across all pages]---------------------//

export type appStateTypes = {
    current: {
        org_id?: string
    }
    persons: {
        fetched_all: boolean
    }
    device_info: {
        screen: string
        browser: string
        browserVersion: string
        browserMajorVersion: number
        mobile: boolean
        os: string
        osVersion: string
        cookies: boolean
        flashVersion: string
    }
}

export type appContextInterface =
    | {
          appState: appStateTypes
          setAppState: React.Dispatch<React.SetStateAction<appStateTypes>>
      }
    | undefined

const AppContext = createContext<appContextInterface>(undefined)

export function AppStateProvider({ ...props }) {
    const [appState, setAppState] = useState<appStateTypes>({
        current: {
            org_id: undefined,
        },
        persons: {
            fetched_all: false,
        },
        device_info: {
            screen: '',
            browser: '',
            browserVersion: '',
            browserMajorVersion: 0,
            mobile: false,
            os: '',
            osVersion: '',
            cookies: false,
            flashVersion: '',
        },
    })

    return (
        <>
            <AppContext.Provider value={{ appState, setAppState }}>{props.children}</AppContext.Provider>
        </>
    )
}

export function useAppState() {
    const context = useContext(AppContext)

    if (!context) {
        throw new Error('useAppState must be within AppStateProvider')
    }
    return context
}
