import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import WordArray from "crypto-js/lib-typedarrays";

import { useAppState } from "../../../../reusable/Context/state";
import { TuttiLoginDeviceMetadata } from "@styreportalen/common/lib/users/tutti_login/tuttilogin";

export enum TuttiQRMode {
  NOTIFICATION,
  QR_LOGIN,
  QR_CREATE,
}

type TuttiLoginRequestRequestData = {
  token: string;
  secret: string;
  deviceMeta: TuttiLoginDeviceMetadata;
};
export default function useTuttiLoginRequestData(mode: TuttiQRMode) {
  const [requestData, setRequestData] =
    useState<TuttiLoginRequestRequestData>();

  const { appState } = useAppState();

  const createRequest = useCallback(() => {
    const token = uuidv4() as string;
    const secret = WordArray.random(
      mode === TuttiQRMode.NOTIFICATION ? 128 : 32
    ).toString();

    const request = {
      token: token,
      secret: secret,
      deviceMeta: {
        platform: appState.device_info.os,
        device: appState.device_info.browser,
      },
    };

    setRequestData(request);
  }, [appState.device_info, mode]);

  useEffect(() => {
    if (appState.device_info) {
      createRequest();
    }

    return () => {
      setRequestData(undefined);
    };
  }, [createRequest, appState.device_info]);

  return { requestData, createRequest };
}
