import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import "firebase/analytics";

const config = {
  apiKey: process.env.apiKey,
  authDomain: process.env.authDomain,
  databaseURL: process.env.databaseURL,
  projectId: process.env.projectId,
  storageBucket: process.env.storageBucket,
  messagingSenderId: process.env.messagingSenderId,
  appId: process.env.appId,
  measurementId: process.env.measurementId,
};

export const firebaseTimestamp = firebase.firestore.Timestamp;
export const firebaseFieldValue = firebase.firestore.FieldValue;
export const RecaptchaVerifier = firebase.auth.RecaptchaVerifier;

export const firebaseApp = firebase.initializeApp(config);

firebase.analytics();

firebaseApp.firestore().settings({
  ignoreUndefinedProperties: true,
});

const fbdb = firebaseApp.firestore();

export const DB = fbdb;

export const Storage = firebaseApp.storage();
export const Auth = firebaseApp.auth();
export const Functions = firebaseApp.functions("europe-west3");

export const UidKey = "styreportalen-uid-key";
export const OrgKey = "styreportalen-org-key";
export const OrgNameKey = "styreportalen-org-name-key";
export const UserNameKey = "styreportalen-user-name-key";
export const RoleKey = "styreportalen-role-key";
export const CalKey = "styreportalen-calendar-key";
export const PinKey = "styreportalen-pin-key";
export const PinFilterKey = "styreportalen-pin-filter-key";
export const SuperAdminKey = "styreportalen-super-admin-key";
