//node modules
import React from "react";

//hooks & functions

//components

//validators

const Div: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        ...(props.style || {}),
      }}
    >
      {props.children}
    </div>
  );
};

export default Div;
