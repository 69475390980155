import { useTranslation } from "react-i18next";
import i18next, { TOptions } from "i18next";
// import { useCurrentFederationId, useCurrentOrgId } from '../components/UserContext/UserContext'
import { trans } from "../i18n";
import { useCallback } from "react";
import { useCurrentOrganization } from "../context/CurrentPersonContext";

/** Translation based on styreportalen's structure. supports organizations and federations and fallback to default translation if none contains.
 * @Params nameSpace file to use first, fallbackNameSpace file to fall back to as secondary.
 * @nameSpace String, default to orgId.
 * @fallbackNameSpace string, defaults to federationId.
 * Defaults to orgId as primary and federationId as secondary. if you have orgId and federationId outside hook send in, to reduce reads.
 * @Return translation or defaultValue
 * If none of primary or secondary finds key it uses default translation
 * */
export default function useSPTranslation(
  nameSpace?: string,
  fallBackNameSpace?: string
) {
  const primary = useOptionalOrgIfNoPrimary(nameSpace);
  const secondary = useOptionalFederationIfNoFallback(fallBackNameSpace);

  const { t } = useTranslation(primary);
  const { t: t2 } = useTranslation(secondary, { useSuspense: true });

  const translator = useCallback(
    (key: string, defaultValue?: string | undefined, options?: TOptions) => {
      let result = t && t(key, undefined, options);

      if (result === key) {
        result = t2 && t2(key, undefined, options);

        if (result === key) {
          return i18next?.t(key, defaultValue, options);
        }
      }

      return result;
    },
    [t, t2]
  );

  return { t: translator };
}

/** Translation function with optional namespace, this works like normal translation except it can use namespace then fallback to default translation and only then default to defaultValue*/
export function spTranslate(
  key: string,
  defaultValue?: string,
  options?: TOptions,
  nameSpace?: string
) {
  let result = nameSpace ? trans(`${nameSpace}:${key}`, options) : key;

  if (result === key) {
    result = i18next?.t(key, defaultValue, options);
  }

  return result;
}

/* this is a workaround for not having hook run every time, if we have nameSpaces we don't get default once */
function useOptionalOrgIfNoPrimary(nameSpace?: string) {
  if (nameSpace) {
    return nameSpace;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCurrentOrganization()?.getId();
}

/* this is a workaround for not having hook run every time, if we have nameSpaces we don't get default once */
function useOptionalFederationIfNoFallback(fallbackNameSpace?: string) {
  if (fallbackNameSpace) {
    return fallbackNameSpace;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCurrentOrganization()?.sentral_ref?.parent.parent?.id;
}
