import i18next from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

/**
 * i18n object
 */
const i18n = {
  init: () => {
    return new Promise((_resolve, reject) => {
      i18next
        .use(Backend)
        .use(initReactI18next)
        .init(
          {
            lng: "no",
            fallbackLng: "en",
            backend: {
              loadPath: "locales/{{lng}}/{{ns}}.json",
            },
            // have a common namespace used around the full app
            ns: ["translations"],
            defaultNS: "translations",
            debug: false,
            react: {
              wait: true,
            },
          },
          (error: any) => {
            if (error) {
              return reject(error);
            }
          }
        )
        .then((r) => r);
    });
  },

  t: (key: string, options?: any) => i18next.t(key, options),

  /**
   * @returns {string}
   */
  get locale() {
    return i18next.language;
  },

  get i18nInstance() {
    return i18next;
  },
};

export default i18n;

export const trans = i18n.t;
