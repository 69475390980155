import React, { useState } from "react";
import { LoginProps } from "../Login";
import LoginMethodInfo from "../components/LoginMethodInfo";
import { Auth } from "../../../firebase/firebase";
import useSPTranslation from "../../../../reusable/useSPTranslation";

const EmailLogin: React.FC<
  LoginProps & { setForgotPass: React.Dispatch<React.SetStateAction<boolean>> }
> = ({ loggingIn, setLoggingIn, error, setForgotPass }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { t } = useSPTranslation();

  const login = async () => {
    error(undefined);
    try {
      setLoggingIn(true);
      if (email === "tokenlogin") {
        await Auth.signInWithCustomToken(password);
      } else {
        await Auth.signInWithEmailAndPassword(email, password);
      }
    } catch (e: any) {
      error(e.code);
    }

    setLoggingIn(false);
  };

  return (
    <div>
      <LoginMethodInfo title={t("components.login.email.text")} />

      <div className="form-group">
        <input
          className="form-control"
          type="text"
          required
          placeholder={t("common.email")}
          disabled={loggingIn}
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
        />
      </div>
      <div className="form-group">
        <input
          className="form-control"
          type="password"
          required
          placeholder={t("common.password")}
          disabled={loggingIn}
          value={password}
          onChange={({ target: { value } }) => setPassword(value)}
        />
      </div>

      <div className="btn btn-link" onClick={() => setForgotPass(true)}>
        {t("components.login.email.forgotPassword")}
      </div>

      <div
        className={`btn btn-primary w-100 ${loggingIn ? "disabled" : ""}`}
        color="primary"
        onClick={login}
      >
        {t("common.login")}{" "}
        {loggingIn && <em className="fas fa-circle-notch fa-spin" />}
      </div>
    </div>
  );
};

export default EmailLogin;
