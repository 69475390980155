import React, { useEffect } from 'react'
import { all as countryCodes } from 'country-codes-list'
import Select from 'react-select'
import { countryFlagEmojiSupport } from './PolyfillFlagsEmojis'

type PickerProps = {
    default_dialCode: string
    selectedCountry: {
        label: string
        value: string
    }
    setSelectedCountry: React.Dispatch<
        React.SetStateAction<{
            label: string
            value: string
        }>
    >
}

const priorityCountries: Record<string, number> = {
    NO: 0,
    DK: 1,
    SE: 2,
    DE: 3,
    GB: 4,
} as const

const ccOptions = countryCodes()
    .sort((a, b) => {
        const aPri = priorityCountries[a.countryCode]
        const bPri = priorityCountries[b.countryCode]

        if (aPri !== undefined || bPri !== undefined) {
            if (aPri !== undefined && bPri !== undefined) {
                return aPri - bPri
            }
            if (aPri !== undefined) {
                return -1
            }
            if (bPri !== undefined) {
                return 1
            }
        }

        return a.countryNameEn.localeCompare(b.countryNameEn)
    })
    .map((cc) => ({
        label: `${cc.flag} ${cc.countryNameEn} (+${cc.countryCallingCode})`,
        value: `+${cc.countryCallingCode}`,
    }))

const DialCodePicker: React.FC<PickerProps> = ({ default_dialCode, selectedCountry, setSelectedCountry }) => {
    useEffect(() => {
        countryFlagEmojiSupport()
        if (default_dialCode) {
            const option = ccOptions.find((a) => a.value === default_dialCode)

            if (option) {
                setSelectedCountry(option)
            }
        }
    }, [default_dialCode, setSelectedCountry])

    return (
        <React.Fragment>
            <div>
                <Select
                    className="custom-select-cc-emoji"
                    value={{ label: selectedCountry.label, value: selectedCountry.value }}
                    options={ccOptions}
                    onChange={(option) => {
                        if (option === null) {
                            setSelectedCountry({ label: 'NO', value: '+47' })
                        } else {
                            setSelectedCountry(option)
                        }
                    }}
                />
            </div>
        </React.Fragment>
    )
}

export default DialCodePicker
