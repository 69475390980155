//import Components
import React, { createContext, useContext, useState } from "react";

//----------------[This is code for global variables across all pages]---------------------//

export type AppDataTypes = {
  [key: string]: any;
};

export type appContextInterface =
  | {
      appData: AppDataTypes;
      setAppData: React.Dispatch<React.SetStateAction<AppDataTypes>>;
    }
  | undefined;

const AppContext = createContext<appContextInterface>(undefined);

export function AppDataProvider({ ...props }) {
  const [appData, setAppData] = useState<AppDataTypes>({});

  return (
    <>
      <AppContext.Provider value={{ appData, setAppData }}>
        {props.children}
      </AppContext.Provider>
    </>
  );
}

export function useAppData() {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("useAppData must be within AppDataProvider");
  }
  return context;
}
