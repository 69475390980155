import styled from '@emotion/styled'

const LoadingScreen = styled.div`
    display: flex;
    background-color: white;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100vh;
`

export default LoadingScreen
