import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import UserContextProvider from "./context/UserContext";

import moment from "moment";
import "moment/locale/nb";
import i18n from "./i18n";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { AppDataProvider } from "./reusable/Context/data";
import { AppStateProvider } from "./reusable/Context/state";

library.add(fas);

moment.locale("nb");
i18n.init();

const root = ReactDOM.createRoot(document.getElementById("app") as HTMLElement);

root.render(
  <AppDataProvider>
    <AppStateProvider>
      <UserContextProvider>
        <App />
      </UserContextProvider>
    </AppStateProvider>
  </AppDataProvider>
);
