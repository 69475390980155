import React, { useState, useEffect } from "react";
import { firebase } from "@styreportalen/common";
import DialCodePicker from "../../../../reusable/DialCodePicker";
import { LoginProps } from "../Login";
import LoginMethodInfo from "../components/LoginMethodInfo";
import useSPTranslation from "../../../../reusable/useSPTranslation";

const SMSLogin: React.FC<LoginProps> = ({ loggingIn, setLoggingIn, error }) => {
  const [sendOTP, setSendOTP] = useState(false);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [selectedCountry, setSelectedCountry] = useState({
    label: "NO",
    value: "+47",
  });

  const [phoneNumber, setPhoneNumber] = useState("");

  const [code, setCode] = useState("");
  const { t } = useSPTranslation();

  useEffect(() => {
    (window as any).recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container-sms",
      {
        size: "normal",
        callback: () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
          setIsButtonDisabled(false);
        },
        "expired-callback": () => {
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
          setIsButtonDisabled(true);
        },
      }
    );
    (window as any).recaptchaVerifier.render().then(function (widgetId: any) {
      (window as any).recaptchaWidgetId = widgetId;
    });

    return () => {
      (window as any).recaptchaVerifier = undefined;
      const captchaContainer = document.getElementById(
        "recaptcha-container-tutti"
      );
      if (captchaContainer) {
        captchaContainer.innerHTML = "";
      }
    };
  }, []);

  const signInWithPhoneNumber = () => {
    error(undefined);
    const appVerifier = (window as any).recaptchaVerifier;

    setLoggingIn(true);
    firebase
      .auth()
      .signInWithPhoneNumber(selectedCountry.value + phoneNumber, appVerifier)
      .then((confirmationResult) => {
        setSendOTP(true);
        (window as any).confirmationResult = confirmationResult;
        setLoggingIn(false);
      })
      .catch((err) => {
        error(err.code);
        setLoggingIn(false);
      });
  };

  const confirmCode = () => {
    setLoggingIn(true);
    (window as any).confirmationResult
      .confirm(code)
      .then((result: any) => {
        setLoggingIn(false);
      })
      .catch((err: any) => {
        error(err.code);
        setLoggingIn(false);
      });
  };

  if (!sendOTP)
    return (
      <div className="d-flex flex-column">
        <LoginMethodInfo title={t("components.login.sms.text")}>
          <span>{t("components.login.sms.info")}</span>
        </LoginMethodInfo>

        <div className="form-group">
          <DialCodePicker
            default_dialCode={"+47"}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
          />
        </div>

        <div className="form-group">
          <input
            className="form-control"
            type="tel"
            disabled={loggingIn}
            value={phoneNumber}
            onChange={({ target: { value } }) =>
              setPhoneNumber(
                value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1")
              )
            }
            onKeyPress={(key) => key.charCode === 13 && signInWithPhoneNumber()}
            placeholder={t("common.mobile_number")}
          />
        </div>

        <div id="recaptcha-container-sms" className="mb-3 mx-auto" />

        <div
          className={`btn btn-primary w-100 ${
            isButtonDisabled || loggingIn ? "disabled" : ""
          }`}
          onClick={signInWithPhoneNumber}
        >
          {t("components.login.sms.get_code")}{" "}
          {loggingIn && <em className="fas fa-circle-notch fa-spin" />}
        </div>
      </div>
    );
  else
    return (
      <div>
        <div className="form-group">
          <input
            className="form-control"
            type="text"
            placeholder={t("components.login.sms.number_label")}
            value={code}
            onChange={({ target: { value } }) => setCode(value)}
            onKeyPress={(key) => key.charCode === 13 && confirmCode()}
          />
        </div>

        <div
          className={`btn btn-primary ${loggingIn ? "disabled" : ""}`}
          style={{ display: "block" }}
          onClick={confirmCode}
        >
          {t("components.login.sms.confirm_code")}{" "}
          {loggingIn && <em className="fas fa-circle-notch fa-spin" />}
        </div>
      </div>
    );
};

export default SMSLogin;
