import crypto from 'crypto-js'

export default function decipherTuttiLoginToken(encrypted: string, secret: string) {
    const k = process.env.tltk
    if (!k) {
        throw new Error('Internal, project is not setup')
    }

    return crypto.AES.decrypt(encrypted, `${k}:${secret.slice(40, 72)}`).toString(crypto.enc.Utf8)
}
