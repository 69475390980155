import React, { ReactNode, useState } from "react";
// import { Button, Collapse } from "reactstrap";
import Div from "../../../../reusable/containers/Div";
import useSPTranslation from "../../../../reusable/useSPTranslation";
import { Button, Collapse } from "@mui/material";

type Props = {
  children?: ReactNode;
  title: string;
};

const LoginMethodInfo: React.FC<Props> = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useSPTranslation();

  return (
    <div style={{ marginBottom: !!children ? "10px" : "30px" }}>
      <div>
        <p
          style={{
            fontSize: "1.2rem",
            textAlign: "center",
            fontWeight: "bold",
            marginBottom: 0,
            textTransform: "uppercase",
          }}
        >
          {title}
        </p>
      </div>

      {!!children && (
        <div>
          <div
            className="btn btn-black"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              fontSize: "0.75rem",
            }}
            onClick={() => setIsOpen((old) => !old)}
          >
            <span>
              {t("common.more")} {t("common.information").toLowerCase()}
            </span>
            <em className={`fa fa-chevron-${isOpen ? "up" : "down"}`} />
          </div>

          {isOpen && (
            <Div
              style={{
                color: "#333",
                fontSize: "0.7rem",
              }}
            >
              <Collapse
                //className="collapse show"
                style={{ width: "90%", margin: "0 auto" }}
                in={isOpen}
              >
                {children}
              </Collapse>
            </Div>
          )}
        </div>
      )}
    </div>
  );
};

export default LoginMethodInfo;
