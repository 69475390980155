import React, { lazy, Suspense } from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PageLoader from "./reusable/loader/PageLoader";
import useSPTranslation from "./reusable/useSPTranslation";

/* Used to render a lazy component with react-router */
export const waitFor = (Tag: any) => (props: any) => <Tag {...props} />;

// Component that will be rendered in place of the component that fails to be loaded
const LoadingFailed: React.FC = () => {
  const { t } = useSPTranslation();
  return (
    <div className="content-wrapper">
      <div
        className="bg-danger p-3 rounded d-flex align-items-center"
        style={{ gap: ".5rem" }}
      >
        <p className="m-0 p-0">{t("loading.failed_desc")}</p>
        <button
          className="btn btn-light text-dark"
          onClick={() => window.location.reload()}
        >
          {t("loading.failed_button")}
        </button>
      </div>
    </div>
  );
};

const retry = (
  fn: () => Promise<{ default: React.ComponentType<any> }>
): Promise<{ default: React.ComponentType<any> }> => {
  return new Promise((resolve) => {
    fn()
      .then(resolve)
      .catch(() =>
        resolve({
          default: LoadingFailed,
        })
      );
  });
};

// SELECT PERSON
const SelectedPerson = lazy(() =>
  retry(() => import("./components/select-page/SelectUserPerson"))
);

// PERSONALIZED-PAGE (STARTPAGE)
const PersonalizedPage = lazy(() =>
  retry(() => import("./components/startpage/PersonPage"))
);

const Routes: React.FC<RouteComponentProps> = ({ location }) => {
  const split = location.pathname.split("/");
  const currentKey = split[1] + "/" + split[2] || "/";
  const timeout = { enter: 500, exit: 500 };

  const selectPath = "select-person";

  return (
    <TransitionGroup>
      <CSSTransition
        key={currentKey}
        timeout={timeout}
        classNames={"rag-fadeIn"}
        exit={false}
      >
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route
              path="/:orgId/:personId/"
              component={waitFor(PersonalizedPage)}
            />

            <Route
              exact
              path={`/${selectPath}`}
              component={waitFor(SelectedPerson)}
            />

            <Route path="/">
              <Redirect to={`/${selectPath}`} />
            </Route>
          </Switch>
        </Suspense>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default withRouter(Routes);
