import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Person, SPOrganization } from "@styreportalen/common";
import { DB } from "../services/firebase/firebase";

export const SelectedPersonContext = createContext<{
  selectedPerson: Person | undefined;
  selectedOrganization: SPOrganization | undefined;
}>({
  selectedPerson: undefined,
  selectedOrganization: undefined,
});

interface CurrentPersonContextProps {
  orgId: string;
  personId: string;
  children: React.ReactNode;
}

const mapToPerson = async (org_id: string, person_id: string) => {
  const person = Person.fromQueryDocumentSnapshot(
    await DB.collection("organizations")
      .doc(org_id)
      .collection("persons")
      .doc(person_id)
      .get()
  );

  return person;
};

const mapToOrganization = async (org_id: string) => {
  const organization = SPOrganization.fromQueryDocumentSnapshot(
    await DB.collection("organizations").doc(org_id).get()
  );

  return organization;
};

export const CurrentPersonContext: React.FC<CurrentPersonContextProps> = (
  props
) => {
  const { orgId, personId } = props;
  const [selectedPerson, setSelectedPerson] = useState<Person | undefined>(
    undefined
  );
  const [currOrganization, setCurrOrganization] = useState<
    SPOrganization | undefined
  >(undefined);

  useEffect(() => {
    async function loadCurrentData(org_id: string, person_id: string) {
      const [person, organization] = await Promise.all([
        mapToPerson(org_id, person_id),
        mapToOrganization(org_id),
      ]);

      setSelectedPerson(person);
      setCurrOrganization(organization);
    }

    if (orgId && personId) loadCurrentData(orgId, personId);
  }, [orgId, personId]);

  return (
    <SelectedPersonContext.Provider
      value={{
        selectedPerson: selectedPerson,
        selectedOrganization: currOrganization,
      }}
    >
      {props.children}
    </SelectedPersonContext.Provider>
  );
};

export const useCurrentPerson = () => {
  const currPerson = useContext(SelectedPersonContext).selectedPerson;
  return useMemo(() => currPerson, [currPerson]);
};

export const useCurrentOrganization = () => {
  const currOrganization = useContext(
    SelectedPersonContext
  ).selectedOrganization;
  return useMemo(() => currOrganization, [currOrganization]);
};
