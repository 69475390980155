import {
  ApplicationsEnum,
  GlobalUserDetails,
  firebase,
} from "@styreportalen/common";
import React, {
  Suspense,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { WithDocumentReferences } from "../reusable/types/wrappers";
import { Auth, DB, Functions } from "../services/firebase/firebase";
import LoadingScreen from "../reusable/loader/LoadingScreen";
import FullLogoLoader from "../reusable/loader/FullLogoLoader";
import Login from "../services/auth/Login/Login";
import PageLoader from "../reusable/loader/PageLoader";

type PersonsOrgs = {
  pid: string;
  oid: string;
};

export const UserContext =
  createContext<WithDocumentReferences<GlobalUserDetails> | null>(null);
export const AuthUserContext = createContext<firebase.User | null>(null);
export const PersonOrgsContext = createContext<PersonsOrgs[] | null>(null);

export default function UserContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [authUser, setAuthUser] = useState<firebase.User | null | undefined>();
  const [user, setUser] = useState<
    WithDocumentReferences<GlobalUserDetails> | undefined
  >();
  const [personOrgs, setPersonOrgs] = useState<PersonsOrgs[] | null>();
  const hasFetched = useRef<string[]>([]);

  useEffect(() => {
    const cancel = Auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        return setAuthUser(authUser);
      }

      setAuthUser(null);

      window.localStorage.removeItem("tutti-web-authToken");
    });

    return cancel;
  }, []);

  useEffect(() => {
    if (!authUser) {
      return;
    }

    DB.collection("users")
      .doc(authUser.uid)
      .get()
      .then((snapshot) => {
        setUser({
          ...(snapshot.data() as GlobalUserDetails),
          documentId: snapshot.id,
          ref: snapshot.ref,
          querySnap: snapshot,
        });
      });
  }, [authUser]);
  useEffect(() => {
    if (!authUser) {
      return;
    }

    DB.collection("users")
      .doc(authUser.uid)
      .onSnapshot((docSS) => {
        if (!docSS.exists) {
          setPersonOrgs(null);
        }

        setPersonOrgs(
          ((docSS.data() as GlobalUserDetails)?.persons_orgs || []).map(
            (data) => ({
              pid: data.pid,
              oid: data.oid,
            })
          )
        );
      });
  }, [authUser, user]);

  useEffect(() => {
    (async () => {
      if (authUser && !hasFetched.current.includes(authUser.uid)) {
        const result = await authUser.getIdTokenResult();

        const update: {
          application: ApplicationsEnum;
          signInProvider: string | null;
          first_time_logged_in: boolean;
        } = {
          application: ApplicationsEnum.MEDLEMSSIDEN,
          signInProvider: null,
          first_time_logged_in: false,
        };

        if (result.signInProvider) {
          update.signInProvider = result.signInProvider;
        }

        if (
          !window.localStorage.getItem("tutti-web-authToken") &&
          window.localStorage.getItem("tutti-web-authToken") !== result.token
        ) {
          // Token has changed
          window.localStorage.setItem("tutti-web-authToken", result.token);
          update.first_time_logged_in = true;
        }

        hasFetched.current.push(authUser.uid);
        Functions.httpsCallable("global-users-updateLastLogin")(update);
      }
    })();
  }, [authUser]);

  if (authUser === undefined) {
    return (
      <LoadingScreen>
        <FullLogoLoader />
      </LoadingScreen>
    );
  }

  if (authUser === null) {
    return (
      <Suspense fallback={<PageLoader />}>
        <Login />
      </Suspense>
    );
  }

  if (user === undefined) {
    return (
      <LoadingScreen>
        <FullLogoLoader />
      </LoadingScreen>
    );
  }

  if (personOrgs === undefined) {
    return (
      <LoadingScreen>
        <FullLogoLoader />
      </LoadingScreen>
    );
  }

  return (
    <AuthUserContext.Provider value={authUser}>
      <UserContext.Provider value={user}>
        <PersonOrgsContext.Provider value={personOrgs}>
          {children}
        </PersonOrgsContext.Provider>
      </UserContext.Provider>
    </AuthUserContext.Provider>
  );
}

export const useUser = () => {
  const user = useContext(UserContext);

  if (!user) {
    throw new Error("No user available");
  }

  return user;
};

export const useAuthUser = () => {
  const authUser = useContext(AuthUserContext);

  if (!authUser) {
    throw new Error("No authUser available");
  }

  return authUser;
};

export const useCurrentPersonOrgs = () => {
  const personOrgs = useContext(PersonOrgsContext);

  return useMemo(() => personOrgs, [personOrgs]);
};

export const useCurrentPersonIds = () => {
  const personOrgs = useContext(PersonOrgsContext);

  return useMemo(() => personOrgs?.map((person) => person.pid), [personOrgs]);
};
