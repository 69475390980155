import styled from "@emotion/styled";
import Logo from "./logo.svg";
import SPLoader from "./SPLoader";

const LogoSVG = styled.object`
  width: 500px;
`;

// TODO: svg does not load with react 18
export default function FullLogoLoader() {
  return <SPLoader />;
  // return <LogoSVG type="image/svg+xml" data={Logo} />;
}
