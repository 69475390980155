import crypto from 'crypto-js'

export function decipherTuttiResponse(res: string | undefined) {
    if (!res) {
        throw new Error('No data received')
    }

    const s = process.env.tlrs
    const k = process.env.tlrk

    if (!s || !k) {
        throw new Error('Internal, project is not setup')
    }

    const decrypted = crypto.AES.decrypt(res, `${k}:${s}`).toString(crypto.enc.Utf8)

    try {
        return JSON.parse(decrypted)
    } catch {
        throw new Error('Invalid response')
    }
}
