import crypto from 'crypto-js'

export function cipherTuttiLogin(values: object | string) {
    const value = typeof values === 'string' ? values : JSON.stringify(values)

    const s = process.env.tlss
    const k = process.env.tlsk

    if (!s || !k) {
        throw new Error('Internal, project is not setup')
    }

    return crypto.AES.encrypt(value, `${k}:${s}`).toString()
}
